<template>
  <div>
    <div
      style="
        font-size: 30px;
        color: #0e702f;
        font-weight: bolder;
        text-align: center;
      "
    >
      APP生产包下载链接
    </div>
    <div
      style="
        font-size: 10px;
        color: #089838;
        font-weight: normal;
        text-align: center;
        margin-top: 10px;
      "
    >
      此文档最后一次更新于：{{ lastUpdated }}
    </div>
  </div>

  <div v-if="downloadLinks == {}">LOADING...</div>

  <div v-else>
    <table>
      <tr
        v-for="n in Math.ceil(Object.keys(downloadLinks).length / rowCount)"
        :key="n"
      >
        <td v-for="i in rowCount" :key="i">
          <div
            class="download-btn-container"
            style="
              display: flex;
              justify-content: space-between;
              border-color: #2fbe5e;
              padding: 5%;
              border-radius: 10px;
              border: 2px solid #2fbe5e;
            "
            :style="{
              flexDirection: isSmallScreen ? 'column' : 'row',
            }"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
            >
              <div style="font-size: 30px; color: #2fbe5e; font-weight: bolder">
                {{ Object.keys(downloadLinks)[(n - 1) * rowCount + i - 1] }}
              </div>
              <div
                class="download-btn"
                @click="
                  download(
                    downloadLinks[
                      Object.keys(downloadLinks)[(n - 1) * rowCount + i - 1]
                    ]
                  )
                "
              >
                点击下载
              </div>
              <div
                class="download-link"
                style="
                  word-wrap: break-word;
                  word-break: break-all;
                  height: auto;
                  max-width: 80%;
                  font-size: small;
                  margin-bottom: 10px;
                "
              >
                {{
                  downloadLinks[
                    Object.keys(downloadLinks)[(n - 1) * rowCount + i - 1]
                  ]
                }}
              </div>
            </div>
            <div style="height: 100%; margin-top: 2%; text-align: center">
              <qrcode-vue
                :value="
                  downloadLinks[
                    Object.keys(downloadLinks)[(n - 1) * rowCount + i - 1]
                  ]
                "
                :size="150"
                level="H"
              />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref } from "vue";

import axios from "axios";
import QrcodeVue from "qrcode.vue";

// 响应式状态
var downloadLinks = ref({});
var lastUpdated = ref(null);
var isMobile = ref(false);

function download(link) {
  window.location.href = link;
}

onBeforeMount(() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "iphone",
    "android",
    "phone",
    "mobile",
    "wap",
    "netfront",
    "opera mobi",
    "operamini ",
    "iemobile ",
    "windows phone ",
    "smartphone ",
    "tablet ",
    "kindle ",
    "playbook",
    "silk - accelerated ",
  ];
  isMobile = mobileKeywords.some((keyword) => userAgent.includes(keyword));
  console.log("isMobile ", isMobile);
});

onMounted(() => {
  axios
    // .get("https://lsq.android666.cf/config.json")
    .get(`./config.json`)
    .then((response) => {
      const data = response.data.data;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        downloadLinks.value[item.name] = item.link;
      }
      lastUpdated.value = response.data.lastUpdateTime;
    })
    .catch((error) => {
      console.error(error);
    });
});

const isSmallScreen = computed(() => {
  const screenWidth = window.screen.width;
  return screenWidth <= 760;
});

const rowCount = computed(() => {
  const screenWidth = window.screen.width;
  return screenWidth >= 900 ? 2 : 1;
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.download-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 80%;
  margin-top: 6%;
  margin-left: 5%;
  margin-right: 5%;
}

.download-btn:hover {
  background-color: #2fbe5e;
  border: 2px solid #2fbe5e;
}

.download-btn {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 20px;
  border-radius: 30px;
  background-color: white;
  border-color: white;
  border: 2px solid #2fbe5e;
  cursor: pointer;
}

.qr-code {
  margin-top: 10px;
  width: 188px;
  height: 188px;
}
</style>
